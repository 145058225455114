<template>
    <v-dialog v-model="dialog" persistent max-width="550">
        <v-card class="pa-3">
            <v-overlay :value="permissionUpdated" opacity="0" transition="scale-transition">
                
                <v-alert
                    dense
                    type="success"
                    absolute
                    elevation="3"
                    :value="permissionUpdated"
                    transition="scale-transition"
                >
                Permission successfully updated
                </v-alert>
            </v-overlay>
            <div class="poppins f18 fw600 d-flex pa-3">
                <div class="primary--text mr-auto">
                    Share
                </div>
                <v-btn icon small right @click="$emit('close')">
                    <v-icon size="18">mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="poppins f14 secondary-2--text px-3">
                Sharing this course will allow other instructors to view or edit the file. Enter the email address below
            </div>
            <div class="poppins f14 secondary-2--text pa-3">
                <span class="f12">Email Address</span>
                <v-autocomplete
                    v-model="email_addresses"
                    :items="instructors"
                    filled
                    chips
                    item-text="name"
                    item-value="id"
                    multiple
                    flat
                    hide-details
                    class="f12 mb-3"
                    :loading="loading">
                    <template v-slot:selection="data">
                        <v-chip
                            outlined
                            color="primary"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data.item)"
                            class="f12 text-wrap"
                            >
                            {{ data.item.name }}
                        </v-chip>
                    </template>
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-list-item-title class="f12"> {{ data.item.name }} </v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
                <v-btn class="f12 poppins" color="primary" :disabled="email_addresses.length === 0" @click="shareCourse" :loading="shareLoading">
                   Share Course
                </v-btn>
            </div>
            <div class="poppins secondary-2--text">
                <span v-if="shared_to_users.length === 0" class="f12">No members yet</span>
                <v-list v-else>
                    <v-list-item v-for="(member, i) in shared_to_users" class="f12" :key="i">
                        <v-row no-gutters class="d-flex align-center justify-space-between">
                            <v-col class="text-wrap">
                                {{ member.full_name }} ({{ member.email }})
                            </v-col>
                            <v-col cols="5">
                                <v-select
                                    :items="roles"
                                    :value="member.pivot.permission"
                                    dense
                                    hide-details
                                    outlined
                                    class="f12"
                                    v-on:change="changePermission(member.id, $event)"/>
                            </v-col>
                        </v-row>
                    </v-list-item>
                </v-list>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
    props: ['dialog', 'course_id', "shared_to_users", "loading"],
    data: () => ({
        email_addresses: [],
        roles: [
            {
                text: 'Viewer',
                value: 'viewer'
            },
            {
                text: 'Editor',
                value: 'editor'
            }
        ],
        permissionUpdated: false,
        shareLoading: false,
    }),
    methods: {
        ...mapActions('instructor', ["shareCourseAction", "updatePermissionAction"]),
        ...mapMutations(["alertMutation"]),

        remove (instructor) {
            const index = this.email_addresses.indexOf(instructor.id)
            if (index >= 0) this.email_addresses.splice(index, 1)
        },

        shareCourse() {
            this.shareLoading = true
            this.shareCourseAction({
                course_id: this.course_id,
                user_id: this.email_addresses
            }).then(() => {
                this.$emit('close')
                this.alertMutation({
                    show: true,
                    text: 'Course successfully shared',
                    type: "success"
                })
            }).catch(() => {
                this.$emit('close')
                this.alertMutation({
                    show: true,
                    text: 'Something went wrong',
                    type: "error"
                })
            }).finally(() => {
                this.shareLoading = false
            })
        },

        changePermission(id, permission){
            this.updatePermissionAction({
                course_id: this.course_id,
                user_id: id,
                permission: permission
            }).then(() => {
                this.permissionUpdated = true
                setTimeout(()=>{
                    this.permissionUpdated = false
                }, 2000)
                
            })
        }
    },
    watch: {
        dialog() {
            this.email_addresses = []
        }
    },
    computed: {
        ...mapState("instructor", {
            instructors: (state) => {
                let _instructors = []

                state.instructors.forEach(instructor => {
                    _instructors.push({
                        name: `${ instructor.first_name ? instructor.first_name : 'Instructor' } ${ instructor.last_name ? instructor.last_name : 'Account' } ${ instructor.suffix ? instructor.suffix : '' } (${ instructor.email })`,
                        id: instructor.id
                    })
                })

                return _instructors
            },
        }),
    }
}
</script>