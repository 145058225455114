<template>
  <section id="assessment-builder">
    <v-expansion-panels accordion flat>
      <v-expansion-panel
        v-for="(item, i) in assessment.assessment_questions"
        :key="i"
        class="panels my-2 secondary-5"
        active-class="toggled"
      >
        <v-expansion-panel-header class="roboto f14 fw500" :id="`panel-header-${i}`">
          <div>
            Question {{ i + 1 }}: 
            <span
              v-if="item.type_of_question"
              class="ml-5 fw500 text-uppercase secondary-2--text"
              >{{ item.type_of_question.replace("_", " ").replace("_", " ") }}</span
            >
            <span v-if="item.score" class="ml-5 fw500 text-uppercase secondary-4--text"
              >{{ item.score }} {{ item.score > 1 ? 'pts' : 'pt' }}</span
            >
          </div>
          <v-spacer />
          <div class="d-flex justify-end" v-if="!$isReadOnly.isReadOnly(status, permission)">
            <v-btn icon @click.stop="removeQuestion(item.id)">
              <v-icon size="18">mdi-trash-can-outline</v-icon>
            </v-btn>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content style="margin-top: -20px">
          <Choices :item="item" :assessment_type="type_of_assessment" :status="status" :permission="permission"/>
          <!-- <Choices :item="item" /> -->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn
      v-if="!$isReadOnly.isReadOnly(status, permission)"
      outlined
      color="primary"
      class=" float-right poppins f12 fw600 text-capitalize my-3"
      @click="$emit('addQuestion', 'not tab')"
      :loading="loading"
    >
      <v-icon left>mdi-plus</v-icon>
      Add Question
    </v-btn>
    <delete-dialog
      :dialog="dialog"
      :id="idToRemove"
      :course_id="this.$store.state.instructor.course.id"
      store="instructor/removeQuestionAction"
      type="Question"
      @close="dialog = false"
    />
  </section>
</template>

<script>
import Choices from "./Choices.vue";
import { mapActions } from "vuex";

export default {
  props: ["assessment", "loading", "type_of_assessment", "status", "permission"],
  components: {
    Choices,
  },
  data: () => ({
    type: 0,
    old: null,

    dialog: false,
    idToRemove: null,
  }),
  methods: {
    ...mapActions("instructor", ["updateQuestionAction", "getAssessmentsAction"]),

    removeQuestion(id) {
      this.idToRemove = id;
      this.dialog = true;
    },
  },

  mounted() {},

  watch: {
    dialog(val) {
      if (!val) {
        this.getAssessmentsAction({
          course_id: this.$store.state.instructor.course.id,
        });
      }
    },
  },
};
</script>

<style scoped>
.toggled {
  background-color: #ffff !important;
  border: 1px solid #bdbdbd !important;
  border-radius: 3px !important;
}
.tox.tox-tinymce {
  border-radius: 5px !important;
}
</style>
