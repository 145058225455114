<template>
  <section id="module-editor">
    <v-row justify="center">
      <v-col cols="12" lg="9">
        <draggable
          v-model="course.modules"
          @end="sortModules"
          ghost-class="ghost"
          v-if="course.modules.length > 0"
          :disabled="$isReadOnly.isReadOnly(course.status, permission) || focus" 
        >
          <transition-group type="transition" name="flip-list" >
            <section
              v-for="item in course.modules"
              :key="item.id">
              <v-expansion-panels
                flat
                accordion
                class="sortable"
              >
                <v-expansion-panel class="custom-border border my-2">
                  <v-expansion-panel-header class="d-flex flex-column">
                    <div class="col-12 ma-0 pa-0 d-flex align-center justify-space-between">
                      <div class="col-md-4">
                        <label class="caption"
                          >MODULE TITLE *</label
                        >
                        <v-text-field
                          @click.stop
                          dense
                          hide-details
                          v-model="item.title"
                          @focus="focus = true"
                          @focusout="focus = false"
                          v-on:change="updateModule($event, item.id)"
                          class="secondary-1--text f14 fw600 poppins"
                          :disabled="$isReadOnly.isReadOnly(course.status, permission)"
                        >
                        </v-text-field>
                      </div>
                      <v-spacer />
                      <ModuleOptions
                        :module="item"
                        :status="course.status"
                        v-if="!$isReadOnly.isReadOnly(course.status, permission)"
                        @addPrerequisite="prerequisite"
                        @showLock="showLock"
                        @deleteModule="deleteItem"
                        @addModuleEvaluation="addModuleEvaluation"
                      />
                    </div>
                    <div class="col-12 ma-0 pa-0">
                      <div class="col-md-8">
                        <label class="caption"
                          >MODULE DESCRIPTION</label>
                        <v-textarea 
                          class="mb-5 mt-3 secondary-1--text f14 fw600 poppins" 
                          dense 
                          v-model="item.description"
                          v-on:change="updateDesc($event, item.id)"
                          rows="2"
                          :disabled="$isReadOnly.isReadOnly(course.status, permission)"
                          counter
                          :rules="[v => v &&  v.length <= 250 || 'Max 250 characters']"
                          @focus="focus = true"
                          @focusout="focus = false"
                          />
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="module">
                    <v-divider />
                    <draggable
                      v-model="item.topics"
                      ghost-class="ghost"
                      @end="sortTopics(item.topics)"
                      class="col-xl-7 col-lg-9"
                      :disabled="$isReadOnly.isReadOnly(course.status, permission)"
                    >
                      <transition-group type="transition" name="flip-list">
                        <div
                          class="py-2"
                          v-for="(topic, i) in item.topics"
                          :key="topic.id"
                          :class="i == 1 ? 'mt-3' : 'mb-2'"
                        >
                          <TopicCard
                            :topic="topic"
                            :status="course.status"
                            :permission="permission"
                            @newSubtopic="newSubtopic"
                            @deleteTopic="deleteItem"
                            @deleteSubtopic="deleteItem"
                            @addAssessment="addAssessment"
                            @viewAssessment="viewAssessment"
                            @sortSubTopic="sortSubTopic"
                            :loading="loading"
                          />
                        </div>
                      </transition-group>
                    </draggable>
                    <div>
                      <label :class="course.status === 'PUBLISH' ? 'mx-8' : 'mx-6'" class="caption" v-if="item.module_evaluations.length > 0">MODULE EVALUATION</label>
                      <ModuleEvaluationCard 
                        v-for="(module_evaluation, i) in item.module_evaluations"
                        :key="i"
                        :evaluation="module_evaluation" 
                        :status="course.status" 
                        :course_id="course.id"
                        :permission="permission"
                        @viewModuleEvaluation="viewModuleEvaluation" 
                        @deleteModuleEvaluation="deleteModuleEvaluation"/>
                      <!-- <ModuleEvaluationCard :evaluation="evaluation" :status="course.status" @viewModuleEvaluation="viewModuleEvaluation"/> -->
                    </div>
                    <div class="ma-2">
                      <v-btn
                        v-if="!$isReadOnly.isReadOnly(course.status, permission)"
                        text
                        small
                        color="primary"
                        class="roboto fw600 f12 text-capitalize ml-2"
                        @click="newTopic(item)"
                      >
                        <v-icon left size="18">mdi-plus</v-icon>Add Topic
                      </v-btn>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </section>
          </transition-group>
        </draggable>
        <div v-else class="roboto f15 fw500 my-5 mx-1 secondary--text">
          No modules available
          <v-divider />
        </div>

        <v-card
          class="custom-border border pt-3 mb-5"
          v-if="course_evaluations.length > 0"
        >
        <label class="caption fw500 mx-6 " v-if="course_evaluations.length > 0">COURSE EVALUATION</label>
          <CourseEvaluationCard 
            v-for="(course_evaluation, i) in course_evaluations"
            :key="i"
            :evaluation="course_evaluation" 
            :status="course.status" 
            :permission="permission"
            @viewCourseEvaluation="viewCourseEvaluation" 
            @deleteCourseEvaluation="deleteCourseEvaluation"/>
        </v-card>

        <div class="my-5" v-if="!$isReadOnly.isReadOnly(course.status, permission)">
          <v-btn
            v-if="!addingModule"
            outlined
            color="primary"
            class="poppins f12 fw600 text-capitalize mr-2"
            @click="addModule"
            dense
          >
            <v-icon left>mdi-plus</v-icon>
            Add Module
          </v-btn>
          <v-btn
            v-else
            outlined
            color="primary"
            class="poppins f12 fw600 text-capitalize mr-2"
            dense
          >
            <v-progress-circular
              indeterminate
              size="18"
              color="primary"
              left
            />&nbsp;Adding...
          </v-btn>
          <v-btn
            outlined
            color="primary"
            class="poppins f12 fw600 text-capitalize"
            @click="addCourseEvaluation"
            dense
            :loading="addingCourseEval"
            v-if="!$isReadOnly.isReadOnly(course.status, permission)"
          >
            <v-icon left>mdi-plus</v-icon>
            Course Evaluation
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" lg="3" class="">
        <CourseNavigator :modules="course.modules" v-if="course.modules.length > 0" :status="course.status"/>
      </v-col>
    </v-row>
    <ModuleLock
      :dialog="showLockedDialog"
      @close="showLockedDialog = false"
      :module="module"
    />
    <AddTopic :dialog="addingTopic" :module="module" @close="addingTopic = false" />
    <AddSubtopic
      :dialog="addingSubtopic"
      :topic="topic"
      :course_id="course.id"
      @close="addingSubtopic = false"
    />
    <DeleteDialog
      :dialog="removeItem"
      :item="itemToDelete"
      :course_id="course.id"
      :type="deleteType"
      @close="removeItem = false"
    />
    <AddPrerequisite
      :dialog="addingPrerequisite"
      :module="module"
      :modules="modules"
      @close="addingPrerequisite = false"
    />
    <delete-toast 
      :dialog="removeCourseEvaluationDialog"
      type="Course Evaluation"
      @delete="removeCourseEvaluation"
      @cancel="removeCourseEvaluationDialog=false"
    />
    <delete-toast 
      :dialog="removeModuleEvaluationDialog"
      type="Module Evaluation"
      @delete="removeModuleEvaluation"
      @cancel="removeModuleEvaluationDialog=false"
    />
  </section>
</template>

<script>
import store from '@/store';
import draggable from "vuedraggable";
import { mapActions, mapState, mapMutations } from "vuex";
import TopicCard from "../../../components/teacher/course/TopicCard.vue";
import CourseNavigator from "../../../components/teacher/course/CourseNavigator.vue";
import ModuleOptions from "../../../components/teacher/course/ModuleOptions.vue";
import ModuleLock from "../../../components/teacher/course/ModuleLock.vue";
import AddTopic from "../../../components/teacher/course/AddTopic.vue";
import AddSubtopic from "../../../components/teacher/course/AddSubtopic.vue";
import DeleteDialog from "../../../components/teacher/course/DeleteDialog.vue";
import AddPrerequisite from "../../../components/teacher/course/AddPrerequisite.vue";
import CourseEvaluationCard from "../../../components/teacher/course/CourseEvaluationCard.vue";
import ModuleEvaluationCard from "../../../components/teacher/course/ModuleEvaluationCard.vue";

export default {
  props: ["course", "loading", "permission"],
  components: {
    TopicCard,
    draggable,
    CourseNavigator,
    ModuleOptions,
    ModuleLock,
    AddTopic,
    DeleteDialog,
    AddSubtopic,
    AddPrerequisite,
    CourseEvaluationCard,
    ModuleEvaluationCard
},
  data: () => ({
    addingModule: false,
    showLockedDialog: false,
    module: [],
    topic: [],
    addingTopic: false,
    readonly: false,
    addingSubtopic: false,
    addingCourseEval: false,
    addingModuleEval: false,

    deleteType: "",
    removeItem: false, //deleteDialog
    itemToDelete: [],

    removeCourseEvaluationDialog: false,
    courseEvaluationToDelete: null,

    removeModuleEvaluationDialog: false,
    moduleEvaluationToDelete: null,

    addingPrerequisite: false,

    moduleEvaluations: [],
    focus: false
  }),
  computed: {
    ...mapState('instructor', {
      modules: (state) => state.modules,
      course_evaluations: (state) => state.course_evaluations,
    }),

    ...mapState({
      user: (state) => state.user,
    }),
    // modules: {
    //   get() {
    //     return this.$store.state['instructor'].modules
    //   },
    //   set(val) {
    //     return this.$store.commit('instructor/modulesMutation', val)
    //   }
    // }
  },
  methods: {
    ...mapMutations([
      'alertMutation'
    ]),

    ...mapActions("instructor", [
      "addModuleAction",
      "showCourseAction",
      "updateModuleAction",
      "sortModuleAction",
      "sortTopicAction",
      "showModulePrerequisiteAction",
      "sortSubTopicAction",
      "addCourseEvaluationAction",
      "deleteCourseEvaluationAction",
      "addModuleEvaluationAction",
      "deleteModuleEvaluationAction"
    ]),

    addAssessment(data) {
      this.$emit('addAssessment', data)
    },

    viewAssessment(data) {
      this.$emit('viewAssessment', data)
    },

    sortModules() {
      let _ids = [];
      this.course.modules.forEach((el) => {
        _ids.push(el.id);
      });

      this.sortModuleAction({
        course_id: this.course.id,
        module_id: _ids,
      });
    },

    addModule() {
      this.addingModule = true;
      this.addModuleAction({
        title: 'Untitled',
        course_id: this.course.id,
      }).then(() => {
        this.addingModule = false;
        this.showCourseAction(this.course.id);
      });
    },

    updateModule(e, id) {
      this.updateModuleAction({
        title: e,
        description: this.course.modules.description,
        // valid_until: '',
        course_id: this.course.id,
        module_id: id,
      }).then(()=>{
        this.alertMutation({
          show: true,
          text: 'Module successfully updated',
          type: "success"
        })
      })
    },

    updateDesc(e, id) {
      this.updateModuleAction({
        title: this.course.modules.title,
        description: e,
        // valid_until: '',
        course_id: this.course.id,
        module_id: id,
      }).then(()=>{
        this.alertMutation({
          show: true,
          text: 'Module successfully updated',
          type: "success"
        })
      })
    },

    showLock(e) {
      console.log(e);
      this.module = e;
      this.showLockedDialog = true;
    },

    newTopic(item) {
      console.log(item);
      this.addingTopic = true;
      this.module = item;
    },

    deleteItem(e) {
      console.log(e);
      this.removeItem = true;
      this.deleteType = e.type;
      this.itemToDelete = e.item;
    },

    newSubtopic(e) {
      console.log(e);
      this.addingSubtopic = true;
      this.topic = e;
    },

    sortTopics(e) {
      let _ids = [];
      console.log(e);
      e.forEach((item) => {
        _ids.push(item.id);
      });
      this.sortTopicAction({
        course_id: this.course.id,
        topic_id: _ids,
      });
    },

    prerequisite(e) {
      this.showModulePrerequisiteAction(e.id).then(() => {
        this.addingPrerequisite = true;
        this.module = e;
      });
    },

    sortSubTopic(e){
      this.sortSubTopicAction({
        course_id: this.course.id,
        sub_topic_id: e,
      });
    },

    addModuleEvaluation(module) {
      this.addModuleEvaluation = true
      this.addModuleEvaluationAction({course_id: this.course.id,
      data: {
        module_id: module.id,
        title: 'Untitled',
        description: '',
        is_shuffle_questions: false,
        is_send_feedback_to_user: false,
        submission_limit: 1,
        is_allow_to_review: false,
        uploader_id: this.user.id,
      }}).then(()=>{
        this.alertMutation({
          show: true,
          text: 'Module Evaluation successfully created',
          type: "success"
        })
        this.addModuleEvaluation = false
      })
    },

    deleteModuleEvaluation(data){
      this.moduleEvaluationToDelete = data
      this.removeModuleEvaluationDialog = true
    },

    removeModuleEvaluation(){
      this.deleteModuleEvaluationAction({...this.moduleEvaluationToDelete, course_id: this.course.id}).then(() => {
        this.removeModuleEvaluationDialog = false
        this.alertMutation({
          show: true,
          text: 'Module Evaluation successfully deleted',
          type: "success"
        })
      })
    },

    viewModuleEvaluation(data) {
      this.$emit('viewModuleEvaluation', data)
    },

    addCourseEvaluation(){
      this.addingCourseEval = true
      this.addCourseEvaluationAction({state: this.questions,
      data: {
        course_id: this.course.id,
        title: 'Untitled',
        description: '',
        is_shuffle_questions: false,
        is_send_feedback_to_user: false,
        submission_limit: 1,
        is_allow_to_review: false,
        uploader_id: this.user.id,
      }}).then(()=>{
        this.alertMutation({
          show: true,
          text: 'Course Evaluation successfully created',
          type: "success"
        })
        this.addingCourseEval = false
      })
    },

    deleteCourseEvaluation(data){
      this.courseEvaluationToDelete = data
      this.removeCourseEvaluationDialog = true
    },

    removeCourseEvaluation(){
      this.deleteCourseEvaluationAction(this.courseEvaluationToDelete).then(() => {
        this.removeCourseEvaluationDialog = false
        this.alertMutation({
          show: true,
          text: 'Course Evaluation successfully deleted',
          type: "success"
        })
      })
    },

    viewCourseEvaluation(data) {
      this.$emit('viewCourseEvaluation', data)
    }
  },
};
</script>
