<template>
  <v-dialog persistent v-model="dialog" max-width="550">
    <v-card class="">
      <v-card-title class="d-flex align-center justify-space-between ma-0 pa-0">
        <h4 class="poppins primary--text px-5 py-4 fw600">Import Rubric</h4>
        <v-btn icon @click="$emit('close')" class="mr-2">
          <v-icon size="20">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-4">
        <div class="secondary-1--text poppins">TITLE</div>
        <v-divider class="my-2" />
        <v-list dense>
          <v-list-item-group v-model="selected" color="primary-1">
            <v-list-item
              v-for="item in data"
              :key="item.id"
              class="roboto secondary-1--text"
              :value="item"
            >
              {{ item.title }}
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex align-center justify-end">
        <v-btn text>Cancel</v-btn>
        <v-btn color="primary" @click="select()">Select Content</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  props: ["dialog", "data"],
  data: () => ({
    selected: "",
    loading: false,
  }),

  methods: {
    ...mapActions("instructor", ["showRubricAction"]),
    ...mapMutations(["alertMutation"]),

    select() {
      console.log(this.selected);
      this.$emit("getRubricID", this.selected.id)
      this.showRubricAction(this.selected.id).then(() => {
        this.$emit("close");
      });
    },
  },
};
</script>
